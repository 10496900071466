import React from 'react';
import cx from 'classnames'
import './../../App.scss'

const Header = props => {
	return (
		<div className={cx("Header")} style={props.style ? props.style : {}}>
			{props.children}
		</div>
	);
};

export default Header;