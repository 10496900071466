import React from 'react';
import PropTypes from 'prop-types';
import {
	IoGameController,
	FaGamepad,
	MdGamepad,
	MdVideogameAsset,
	RiGameFill,
	IoDice,
	ImDice,
	GiDiceEightFacesEight,
	ImTarget,
	FiTarget,
	GiTriforce,
	GiChest,
	GiCharm,
	GiTwoCoins
} from 'react-icons/all'

import styles from './Separator.module.scss'

const Separator = props => {
	const getIcon = () => {
		if (!props.icon) return undefined;
		let icon = props.icon.toLowerCase();
		switch (icon) {
			case "controller": return <IoGameController />
			case "gamepad": return <MdGamepad />
			case "nescontroller": return <MdVideogameAsset />
			case "snescontroller": return <FaGamepad />
			case "pacman": return <RiGameFill />
			case "orthodice":
			case "3ddice": return <IoDice />
			case "dice":
			case "die": return <ImDice />
			case "d20": return <GiDiceEightFacesEight />
			case "crosshairs":
			case "crosshair": return <ImTarget />
			case "target": return <FiTarget />
			case "triforce": return <GiTriforce />
			case "treasure":
			case "chest": return <GiChest />
			case "hearts":
			case "heart": return <GiCharm />
			case "coins": return <GiTwoCoins />
			default: return undefined;
		}
	}

	let { style = {} } = props;

	return (
		<div className={styles.Container} style={style}>
			<div style={{ width: "calc(50% - 30px)", height: "2px", border: "1px solid #e0e0e075", display: "flex", alignItems: "center" }}></div>
			<div style={{ width: "60px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "20px", color: "#e0e0e075" }}>{getIcon()}</div>
			<div style={{ width: "calc(50% - 30px)", height: "2px", border: "1px solid #e0e0e075", display: "flex", alignItems: "center" }}></div>
		</div>
	);
};

Separator.propTypes = {
	icon: PropTypes.string
};

export default Separator;