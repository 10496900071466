import './App.scss';
import '@ionic/react/css/core.css';
import HomePage from './Pages/HomePage'

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
