export const SOCIAL_TYPES = {
	TWITTER: {
		name: "TWITTER",
		url: "https://www.twitter.com/jammerspodcast"
	},
	INSTAGRAM: {
		name: "INSTAGRAM",
		url: "https://www.instagram.com/jammerspodcast"
	},
	DISCORD: {
		name: "DISCORD",
		url: ""
	}
}