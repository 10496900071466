import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './../../../App.scss';
import styles from './Alert.module.scss'
import cx from 'classnames'

const Alert = props => {
	const [show, setShow] = useState(false);
	useEffect(() => {
		setShow(props.Show);
		if (props.Show) {
			setTimeout(() => props.resetAlert(), 3000)
		}
	}, [props.Show])
	return (
		<div className={cx(styles.AlertWrapper, show ? styles.ShowWrapper : undefined)}>
			<div className={cx(styles.Alert, show ? styles.Show : undefined)}>
				<p className={cx("Paragraph", styles.Children)}>
					{props.children}
				</p>
			</div>
		</div>
	);
};

Alert.propTypes = {
	Show: PropTypes.bool.isRequired,
	resetAlert: PropTypes.func.isRequired
};

export default Alert;