import React from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss'
const Input = props => {
	return (
		<>
			<input className={styles.Input} onChange={props.onChange} value={props.value} placeholder={props.placeholder || ""} />
			{props.error && (<p className={styles.Error}>{props.error}</p>)}
		</>
	);
};

Input.propTypes = {

};

export default Input;