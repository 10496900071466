import React, { useState, useEffect } from 'react';
import { IoSend, IoSendOutline, IoMail, IoCopy } from 'react-icons/io5'

import './../App.scss';
import styles from './HomePage.module.scss';
import cx from 'classnames'

import Banner from './../Components/Banner/Banner'
import Header from './../Components/Header/Header'
import Input from './../FormComponents/Input/Input'
import Button from './../FormComponents/Button/Button'
import Spacer from './../Components/Spacer'
import MessageBlock from './../Components/MessageBlock/MessageBlock'
import Separator from './../Components/Separator/Separator'
import Error from './../Components/Error/Error'
import Alert from './../Components/HOC/Alert/Alert'
import Social from './../Components/Social/Social'

import Patreon from './../images/Patreon.png'
import { SOCIAL_TYPES } from '../Utils/Constants';


const HomePage = props => {
	const [color, setColor] = useState(0);
	const [theme, setTheme] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const [themeError, setThemeError] = useState("");
	const [email, setEmail] = useState("");
	const [submittingEmail, setSubmittingEmail] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [copyAlert, setCopyAlert] = useState(false);
	const dogeWallet = "DAKTMcg436xzUw81yeTX9oPqqPUqC1zCRn";

	useEffect(() => {
		randomizeColor();
	}, [])

	const randomizeColor = () => {
		let newColor = Math.floor(Math.random() * 10);
		setColor(newColor);
	}

	const getColor = colorType => {
		switch (colorType) {
			case 0: return styles.RedBG;
			case 1: return styles.OrangeBG;
			case 2: return styles.PurpleBG;
			case 3: return styles.FushiaBG;
			case 4: return styles.BlueBG;
			case 5: return styles.GreenBG;
			case 6: return styles.YellowBG;
			case 8:
			case 9:
			default: return styles.TealBG;
		}
	}
	const handleTheme = e => {
		const maxLength = 200;
		let newTheme = e.target.value;
		if (newTheme.length > maxLength) {
			newTheme = newTheme.substring(0, maxLength);
		}
		setTheme(newTheme);
	}



	const submitTheme = () => {
		if (!theme) return;
		setThemeError("")
		setSubmitting(true);
		let formattedTheme = theme;
		formattedTheme = formattedTheme.replace("{", " ");
		formattedTheme = formattedTheme.replace("}", " ");
		formattedTheme = formattedTheme.replace(":", "--");
		const fetchOptions = {
			method: "PUT",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "https://www.jammerspodcast.com, https://jammerspodcast.com",
			"Accept": "*/*",
			body: JSON.stringify({ theme: formattedTheme })
		}

		fetch('https://5wbtscnwq7.execute-api.us-east-1.amazonaws.com/addGameTheme', fetchOptions)
			.then(res => {
				alert("Thanks for submitting a theme!");
				setSubmitting(false);
				setTheme("");
			})
			.catch(err => {
				setThemeError(err.message);
				setSubmitting(false);
			})
	}

	const gotoPatreon = () => {
		window.open("https://patreon.com/jammerspodcast", "_blank")
	}

	const submitEmail = () => {
		if (!validateEmail) {
			setEmailError("Please use a valid email address")
			return;
		}
		setEmailError("");
		setSubmittingEmail(true);
		const fetchOptions = {
			method: "PUT",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "https://www.jammerspodcast.com, https://jammerspodcast.com",
			"Accept": "*/*",
			body: JSON.stringify({ email: email.trim().toLowerCase() })
		}

		fetch('https://nvehrnghe0.execute-api.us-east-1.amazonaws.com/jammersEmailList', fetchOptions)
			.then(res => {
				alert("Email added!");
				setSubmittingEmail(false);
				setEmail("");
			})
			.catch(err => {
				setEmailError(err.message);
				setSubmittingEmail(false);
			})
	}

	const validateEmail = () => {
		if (!email || email.trim() === "") return;
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email.toLowerCase());
	}

	const copyDoge = () => {
		if (!navigator) return;
		navigator.clipboard.writeText(dogeWallet)
		setCopyAlert(true);
	}

	const resetAlert = () => {
		setCopyAlert(false);
	}

	return (
		<div className={"PageWrapper"}>
			<Banner color={getColor(color)} />
			<main>
				<MessageBlock icon="mic" width="600px" containerStyle={{ margin: "0px auto", marginTop: "30px" }}>
					<Header style={{ textAlign: "center", textShadow: "0px 4px 8px rgba(0,0,0,0.25)" }}>Available now!</Header>
					<Spacer size="10px" />
					<iframe src="https://anchor.fm/jammerspodcast/embed" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
					<Spacer />
					<p className={"Paragraph"}>
						Get notified when we release our next episode:
					</p>
					<Spacer size="15px" />
					<section className={styles.InputSection} style={{ padding: "0px 10px" }}>
						<Input placeholder="Share your email..." value={email} onChange={e => setEmail(e.target.value)} />
						<Button onClick={submitEmail} disabled={submittingEmail || email.trim() === ""} color={getColor(color)}>
							<IoMail />
						</Button>
					</section>
					{
						themeError && (
							<Error error={emailError} />
						)
					}
				</MessageBlock>

				<Spacer />
				<section className={styles.SuggestionBox}>
					<Header style={{ textAlign: "center", textShadow: "0px 4px 8px rgba(0,0,0,0.25)" }}>Suggest a game jam theme:</Header>
					<Spacer size="5px" />
					<section className={styles.InputSection}>
						<Input placeholder="Enter a theme..." value={theme} onChange={e => setTheme(e.target.value)} />
						<Button onClick={submitTheme} disabled={submitting || theme.trim() === ""} color={getColor(color)}>
							<IoSend />
						</Button>
					</section>
					{
						themeError && (
							<Error error={themeError} />
						)
					}
				</section>
				<Spacer />
				<MessageBlock icon="controller" width="600px" containerStyle={{ margin: "0 auto" }} childStyle={{ textAlign: "center" }}>
					<Header style={{ width: "calc(100% - 40px)", padding: "10px 0px", borderBottom: "1px solid #e0e0e0" }}>What's Jammers?</Header>
					<Spacer size="10px" />
					<p className={cx("Paragraph")} style={{ padding: "10px" }}>
						Jammers podcast is dedicated to game design ideation.
						Each episode we draw a theme from those provided by listeners and design a game around it.
						The games we design are scoped to be built in a week or weekend game jam.
					</p>
					<p className={cx("Paragraph")} style={{ padding: "10px" }}>
						Follow us on Twitter for updates:
					</p>
					<section className={styles.Twitter}>
						<Social type={SOCIAL_TYPES.TWITTER.name} />
					</section>
				</MessageBlock>
				<Separator icon="pacman" style={{ width: "600px" }} />
				<MessageBlock
					width="600px"
					icon="hearts"
					containerStyle={{ margin: "0 auto", minHeight: "300px" }}
					innerStyle={{ minHeight: "290px" }}
				>
					<div className={styles.Support}>
						<section className={styles.Patreon} onClick={gotoPatreon}>
							<section className={getColor(color)}>
								<Header style={{ textAlign: "center", fontWeight: 600, textShadow: "0px 4px 8px rgba(0,0,0,0.25)", color: "#2b2b2b" }}>Support us: </Header>
							</section>
							<div>
								<img src={Patreon} className={styles.PatreonImg} alt="Support Jammers on Patreon" />
							</div>
						</section>
						<section className={styles.SupportInfo}>
							<Header style={{ textAlign: "center", width: "calc(100% - 40px)", padding: "10px 0px", borderBottom: "1px solid #e0e0e0" }}>Why donate?</Header>
							<Spacer size="10px" />
							<p className={cx("Paragraph")}>
								We love to create content, but let's face it, we all have bills.
								Donating is 100% optional and helps us make Jammers a priority. Whether it's dollars or dogecoin, all forms of support are welcome.
							</p>
							<Separator icon="coins" />
							<p className={cx("Paragraph")}>
								Dogecoin donations can be sent to:
							</p>
							<div className={styles.DogeContainer}>
								<div className={cx("Paragraph", styles.DogeText)}>
									{dogeWallet}
								</div>
								<div className={styles.DogeCopy}>
									<Button onClick={copyDoge} color={getColor(color)} style={{ width: "40px", height: "40px" }}>
										<IoCopy />
									</Button>
								</div>
							</div>

						</section>
					</div>
				</MessageBlock>
				<Spacer />
				<MessageBlock width="600px" containerStyle={{ margin: "0 auto" }} icon="podcast">
					<Header style={{ textAlign: "center", width: "calc(100% - 40px)", padding: "10px 0px", borderBottom: "1px solid #e0e0e0" }}>
						Previously on Jammers
					</Header>
					<Spacer size="40px" />
					<iframe src="https://anchor.fm/jammerspodcast/embed/episodes/Jammers-Episode-1-featuring-Matt---Frozen-Flame-Interactive-er82h3/a-a4pjvug" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
					<Spacer />
					<iframe src="https://anchor.fm/jammerspodcast/embed/episodes/Jammers-Episode-2-featuring-Lloyd---Red-Iron-Labs-eruaoj/a-a4rve8r" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
					<Spacer />
					<Button color={getColor(color)}>
						<a
							href="https://jammerspodcast.itch.io/finding-binky"
							target="_blank"
							className={styles.Link}
						>
							*Bonus - Spoiler Alert*<br />Play the game from Ep. 2!
						</a>
					</Button>
					<Spacer />
					<iframe src="https://anchor.fm/jammerspodcast/embed/episodes/Jammers-Episode-3-featuring-Munglo---TwitchTV-Streamer-esf8s2/a-a4u0nvg" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
					<Spacer />
					<iframe src="https://anchor.fm/jammerspodcast/embed/episodes/Jammers-Episode-4-featuring-Matt---Frozen-Flame-Interactive-etl2e2" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
					<Spacer />
					<iframe src="https://anchor.fm/jammerspodcast/embed/episodes/Jammers-Episode-5-featuring-Honest-Dan-Games-eumpur" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
				</MessageBlock>
				<Alert Show={copyAlert} resetAlert={resetAlert} >
					Copied!
				</Alert>
				<Spacer size="160px" />
			</main>
		</div>
	);
};

export default HomePage;