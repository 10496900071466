import React from 'react';
import PropTypes from 'prop-types';
import {
	IoGameController,
	FaGamepad,
	MdGamepad,
	MdVideogameAsset,
	RiGameFill,
	IoDice,
	ImDice,
	GiDiceEightFacesEight,
	ImTarget,
	FiTarget,
	GiTriforce,
	GiChest,
	GiCharm,
	FaMicrophoneAlt,
	BsFillMusicPlayerFill
} from 'react-icons/all'


import styles from './MessageBlock.module.scss'
import cx from 'classnames'

const MessageBlock = props => {
	const getIcon = () => {
		if (!props.icon) return undefined;
		let icon = props.icon.toLowerCase();
		switch (icon) {
			case "controller": return <IoGameController />
			case "gamepad": return <MdGamepad />
			case "nescontroller": return <MdVideogameAsset />
			case "snescontroller": return <FaGamepad />
			case "pacman": return <RiGameFill />
			case "orthodice":
			case "3ddice": return <IoDice />
			case "dice":
			case "die": return <ImDice />
			case "d20": return <GiDiceEightFacesEight />
			case "crosshairs":
			case "crosshair": return <ImTarget />
			case "target": return <FiTarget />
			case "triforce": return <GiTriforce />
			case "treasure":
			case "chest": return <GiChest />
			case "hearts":
			case "heart": return <GiCharm />
			case "mic": return <FaMicrophoneAlt />
			case "podcast": return <BsFillMusicPlayerFill />
			default: return undefined;
		}
	}
	let { containerStyle = {}, innerStyle = {}, iconStyle = {}, childStyle = {} } = props;
	if (props.width) {
		containerStyle.width = props.width;
	}
	if (props.height) {
		containerStyle.height = props.height;
	}
	return (
		<div className={styles.Container} style={containerStyle}>
			<div className={styles.InnerContainer} style={innerStyle}>
				<div className={styles.ChildContainer} style={childStyle}>
					{props.children}
				</div>
				<div className={styles.Icon} style={iconStyle}>{getIcon()}</div>
			</div>
		</div>
	);
};

MessageBlock.propTypes = {

};

export default MessageBlock;