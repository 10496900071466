import React from 'react';
import PropTypes from 'prop-types';

const Error = props => {
	return (
		<div
			style={{ 
				width: props.width || "100%",
				display: "flex",
				alignItems: "center", 
				justifyContent: "center",
				color: "#e0e0e0",
				margin: "10px auto",
				backgroundColor: props.backgroundColor || "#884141",
				borderRadius: "5px",
				padding: "10px"
			}}
		>
			{props.error}
		</div>
	);
};

Error.propTypes = {
	error: PropTypes.string
};

export default Error;