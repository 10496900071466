import React from 'react';

const Spacer = ({ size = "40px" }) => {
	return (
		<div style={{ minHeight: size, width: '100%' }}>

		</div>
	);
};

export default Spacer;