import React, { useState, useEffect } from 'react';

import Logo from './../../images/Logo.png';
import Logo2 from './../../images/PodcastLogo.png'
import Social from './../Social/Social'
import { SOCIAL_TYPES } from './../../Utils/Constants'
import styles from './Banner.module.scss';
import cx from 'classnames'

const Banner = props => {

	let { color } = props;
	return (
		<div className={styles.BackgroundWrapper}>
			<img src={Logo2} className={styles.Logo} alt="Jammers Podcast" />
			<div className={cx(styles.Background, color)}>
			</div>
			<section className={styles.Socials}>
				<Social type={SOCIAL_TYPES.TWITTER.name} />
				{/* <Social type={SOCIAL_TYPES.INSTAGRAM.name} />
				<Social type={SOCIAL_TYPES.DISCORD.name} /> */}
			</section>
		</div>
	);
};


export default Banner;