import React from 'react';
import PropTypes from 'prop-types';
import { SOCIAL_TYPES } from './../../Utils/Constants'
import {
	AiFillTwitterCircle,
	AiFillInstagram,
	FaDiscord
} from 'react-icons/all'

import styles from './Social.module.scss'

const Social = props => {
	const getIcon = () => {
		let { type } = props;
		switch (type) {
			case SOCIAL_TYPES.TWITTER.name: return <AiFillTwitterCircle />
			case SOCIAL_TYPES.INSTAGRAM.name: return <AiFillInstagram />
			case SOCIAL_TYPES.DISCORD.name: return <FaDiscord />
		}
	}
	const goto = () => {
		let type = SOCIAL_TYPES[props.type]
		if (!type) {
			return;
		}
		window.open(type.url, "_blank");
	}
	return (
		<div className={styles.Container}>
			<a
				href=""
				onClick={goto}
				target="_blank"
				className={styles.Link}
			>{getIcon()}</a>
		</div>
	);
};

Social.propTypes = {
	type: PropTypes.oneOf(Object.keys(SOCIAL_TYPES))
};

export default Social;