import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss'
import cx from 'classnames'

const Button = props => {
	const getClassName = () => {
		let { type = "standard" } = props;
		switch (type) {
			case "standard":
			default: return styles.Standard;
		}
	}
	return (
		<button
			className={cx(styles.Button, getClassName(), props.color ? props.color : "")}
			onClick={props.onClick}
			disabled={props.disabled}
			style={props.style || {}}>
			{props.children}
		</button>
	);
};

Button.propTypes = {

};

export default Button;